/*
 * @Descripttion:
 * @Author: liuxia
 * @Date: 2022-06-14 15:50:49
 * @LastEditors: liuxia
 * @LastEditTime: 2022-10-26 14:44:51
 */
import http from '@/utils/http';
import { QyStlBillDeType } from '@/interface/costQuotaLibrary';
export interface QyStlBillDeParamsType {
  id?: string;
  agencyCode?: string;
  openId?: string;
  qdIndex?: number;
  deIndex?: number;
  qyStlBillDeList: Partial<QyStlBillDeType>[];
}

// 清单聚合分页查询
export function qyStlBillDe(params) {
  return http.get<{ total: number; list: QyStlBillDeType[] }>(
    '/cocc-quotalibrary-resource/1/qyStlBillDe',
    params
  );
}

/**
 * 批量导入预览校验
 * @param ossUrl
 * @returns
 */
export function enterpriseQuota(ossUrl) {
  return http.post<{
    code: number;
    data: QyStlBillDeType[];
    msg: string;
  }>(`/cocc-quotalibrary-resource/1/qyStlBillDe/preview/enterpriseQuota`, {
    ossUrl,
  });
}

/**
 * 上传批量保存
 * @param params
 * @returns
 */
export function qyStlBillDeSave(params) {
  return http.post<boolean>(
    '/cocc-quotalibrary-resource/1/qyStlBillDe/saveBatch',
    params
  );
}

/**
 * 检测清单是否有相同
 * @param params
 * @returns
 */
export function queryOtherMajorQd(params: {
  bdCode: string;
  bdName: string;
  projectAttr: string;
  unit: string;
  majorNbr: string;
  agencyCode: string;
}) {
  return http.post<boolean>(
    '/cocc-quotalibrary-resource/1/qyStlBillDe/queryOtherMajorQd',
    params
  );
}

/**
 * 清单列表
 * @returns
 */
export function listByTree() {
  return http.get(
    '/cocc-quotalibrary-resource/1/projectListLevelTotal/listByTree'
  );
}

/**
 *
 * @param bdName
 * @returns
 */
export function qyStlBillDeTreeByBdName(bdName) {
  return http.get(`/cocc-quotalibrary-resource/1/qyStlBillDe/${bdName}`);
}

/**
 * 组价成本库缓存列表
 * @param id
 * @returns
 */
export function qyStlBillDeCacheList(id, agencyCode, openId) {
  return http.get<QyStlBillDeType[]>(
    `/cocc-quotalibrary-resource/1/qyStlBillDe/scheme/${id}/${agencyCode}/${openId}`
  );
}

/**
 * 定额缓存保存
 * @param params
 * @returns
 */
export function quotaBatchCacheSave(params: QyStlBillDeParamsType) {
  return http.post(
    '/cocc-quotalibrary-resource/1/qyStlBillDe/batch/saveRedis',
    params
  );
}

/**
 * 组价成本库缓存保存
 * @param params
 * @returns
 */
export function qyStlBillDeCacheSave(params: QyStlBillDeParamsType) {
  return http.post<QyStlBillDeType | null>(
    '/cocc-quotalibrary-resource/1/qyStlBillDe/scheme/save',
    params
  );
}

/**
 * 清楚组价定额缓存
 * @param sequenceNbr
 * @param username
 * @returns
 */
export function deleteRedis(sequenceNbr, agencyCode, openId) {
  return http.get(
    `/cocc-quotalibrary-resource/1/qyStlBillDe/deleteRedis/${sequenceNbr}/${agencyCode}/${openId}`
  );
}

/**
 * 智能推荐
 * @param params
 * @returns
 */
export function recommendAccurate(params) {
  return http.post<QyStlBillDeType[]>(
    '/cocc-quotalibrary-resource/1/call/other/server/mtds/accurate/recommend',
    params
  );
}

// 使用清单code、项目名称、单位3要素删除方案数据
export function deleteQyStlBillDe(params) {
  return http.delete('/cocc-quotalibrary-resource/1/qyStlBillDe', params);
}

// redis中保存关联的定额列表.
export function saveRedis() {
  return http.post('/cocc-quotalibrary-resource/1/qyStlBillDe/batch/saveRedis');
}

// 查询关联的定额列表
export function qyStlBillDeByBdName(bdName: string) {
  return http.get(`/cocc-quotalibrary-resource/1/qyStlBillDe/${bdName}`);
}

// 查询分类下的定额.
export function queryListByClassify(params) {
  return http.post<QyStlBillDeType[]>(
    '/cocc-quotalibrary-resource/1/adsStlDeFullLibraryFull/queryListByClassify',
    params
  );
}

// 定额册下拉列表.
export function typeList() {
  return http.get(
    '/cocc-quotalibrary-resource/1/adsStlDeFullLibraryFull/typeList'
  );
}
// 定额树形结构.
export function libraryFullListByTree(name: string) {
  return http.get(
    `/cocc-quotalibrary-resource/1/adsStlDeFullLibraryFull/listByTree/${name}`
  );
}

// 模糊搜索前10条.
export function likeByBdCodeAndName(params: any) {
  return http.post(
    `/cocc-quotalibrary-resource/1/adsStlDeFullLibraryFull/likeByBdCodeAndName`,
    params
  );
}
// 高级搜索分类下的定额.
export function queryListByBdCodeAndName(params) {
  return http.post(
    '/cocc-quotalibrary-resource/1/adsStlDeFullLibraryFull/queryListByBdCodeAndName',
    params
  );
}

/**
 * 定额明细修改保存到合并
 * @returns
 */
export function saveToMerge(params: {
  xiliOpenId: string;
  agencyCode: string;
  qyStlBillDeList: Partial<QyStlBillDeType>[];
  rawData: Partial<QyStlBillDeType>[];
}) {
  return http.post<QyStlBillDeType[]>(
    '/cocc-quotalibrary-resource/1/qyStlBillDe/saveToMerge',
    params
  );
}
