/*
 * @Descripttion:
 * @Author: renmingming
 * @Date: 2022-06-07 09:58:43
 * @LastEditors: renmingming
 * @LastEditTime: 2022-09-23 20:27:23
 */

import { MaterialType } from './materialMachineLibrary'

/**
 * 操作类型
 */
export enum TableActionType {
  // open 展开
  OPEN = 'open',
  // delete 删除
  DELETE = 'delete',
  // view 查看
  VIEW = 'view',
  // cost 组价成本
  COST = 'cost',
  // copy 复制清单项
  COPY = 'copy',
  // edit 编辑
  EDIT = 'edit',
  // select选择
  SELECT = 'select',
  // 应用
  USE = 'use',
}

export interface MajorType {
  sequenceNbr: string;
  promajorName: string;
  oldName: string;
  majortype: number;
  parentCode: string;
  majorList: MajorType[];
}

export interface QyStlBillDeType {
  sequenceNbr: string;
  bdCode: string;
  bdName: string;
  unit: string;
  rfee: number;
  cfee: number;
  jfee: number;
  managerFee: number;
  quantity: number;
  price: number | null;
  projectAttr: string;
  priceRange: number;
  deList?: QyStlBillDeType[];
  isError?: number;
  isNull?: number;
  customIndex?: number;
  majorNbr?: number;
  id: string;
  adsQycbRcjModelList?: MaterialType[];
  replacePrice?: number;
  customClassName?: string; // 自定义类名
}
