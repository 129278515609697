















































































































import Vue from 'vue';
const componentsMap = {
  input: 'a-input',
  textarea: 'a-textarea',
  number: 'a-input-number',
};
const pagination = {
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '20', '30', '40', '50'],
  total: 0,
  showTotal: (total: number) => {
    return `共 ${total} 条`;
  },
};
export default Vue.extend({
  name: 'baseTable',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    /**
     * 操作列表
     */
    actionList: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    /**
     * 需要自定义字段
     */
    fieldSlots: {
      type: Array,
      default: () => [],
    },
    /**
     * 需要编辑字段
     */
    editFields: {
      type: Array,
      default: () => [],
    },
    rowSelection: {
      type: Object,
      default: () => null,
    },
    rowKey: {
      type: String,
      default: 'sequenceNbr',
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    showExpandedRow: {
      type: Boolean,
      default: false,
    },
    customAction: {
      type: Boolean,
      default: false,
    },
    actionWidth: {
      type: String,
      default: '90px',
    },
    pagination: {
      type: Object,
      default: () => pagination,
    },
    expandIconAsCell: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    getComponentName(type = 'input') {
      return componentsMap[type];
    },
    /**
     * 编辑失焦事件
     */
    editFieldHandler(record, key, index, eventName) {
      this.$emit('editHandler', { record, key, index, eventName });
    },
    actionHandler(record, key, index, e) {
      this.$emit('actionHandler', { record, key, index, e });
    },
    setClassName(field, record) {
      const name = record.customCellClass && record.customCellClass[field.key];
      return name;
    },
    customRow(record, index) {
      return {
        on: {
          click: (e) => {
            this.$emit('preOperation', e);
          },
          dblclick: (e) => {
            if (e.target.className.indexOf('btn') >= 0) return;
            this.$emit('selectedData', { record, index, e });
          },
        },
      };
    },
    paginationChange(pagination) {
      this.$emit('paginationChange', pagination);
    },
  },
});
