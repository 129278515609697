/*
 * @Author: zhangguoliang
 * @Date: 2021-09-22 13:50:56
 * @LastEditors: liuxia
 * @LastEditTime: 2022-09-23 20:45:42
 */

export interface Tag {
  path: string;
  name: string;
  query?: Record<string, string | (string | null)[]>;
}

export interface MenuItem {
  path: string;
  location?: string;
  title: string;
  icon?: string;
  hidden?: boolean;
  children?: MenuItem[];
  subMenuKey?: string[];
}

export type RuleObject = {
  required?: boolean;
  trigger: 'blur' | 'change' | ['change', 'blur'];
  message?: string;
  type?: string;
  len?: number;
  max?: number;
  min?: number;
  pattern?: RegExp;
  validator?: (
    rule: RuleObject,
    value: string | Array<string>,
    callback: (msg: string | Error) => unknown
  ) => void;
  whitespace?: boolean;
};

interface ItemConfig {
  label: string;
  prop: string;
  rules?: RuleObject;
}

export interface FormItemConfig {
  type: string;
  slot?: boolean;
  itemConfig: ItemConfig;
  innerConfig?: Record<string, unknown>;
  valueType?: string;
  hidden?: boolean;
}

export type Raw = Record<
  string,
  string | number | Array<string | number> | null
>;

// EMPTY 空项目, UNBUILD 未组价，BUILD 已组价
export enum ProjectStatus {
  EMPTY = 'EMPTY',
  UNBUILD = 'UNBUILD',
  BUILD = 'BUILD',
}

export interface BaseResponse<ResultType = any> {
  code: string;
  message: string;
  result: ResultType;
  status: number;
  traceId: null | string;
}

export interface UserInfoType {
  agencyType: boolean;
  permissionType: string;
  agencyCode: string;
  username: string;
  agencyName: string;
  isQuotaLibraryIdentify: number;
  userType: string;
  enterprisePassed: boolean;
}

export enum TableCalcStatus {
  // update 更新表格计算
  UPDATE = 'update',
  // reduce 表格减小更新
  REDUCE = 'reduce',
  // enlarge 表格放大更新
  ENLARGE = 'enlarge',
}

/**
 * 数值限制
 */
export enum MaxNumberLimit {
  // amount 量限制
  AMOUNT = 100000,
}
