import { render, staticRenderFns } from "./baseTable.vue?vue&type=template&id=5dc78bfb&scoped=true&"
import script from "./baseTable.vue?vue&type=script&lang=ts&"
export * from "./baseTable.vue?vue&type=script&lang=ts&"
import style0 from "./baseTable.vue?vue&type=style&index=0&id=5dc78bfb&lang=less&scoped=true&"
import style1 from "./baseTable.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dc78bfb",
  null
  
)

export default component.exports