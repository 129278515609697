/*
 * @Descripttion:
 * @Author: renmingming
 * @Date: 2022-06-23 10:04:31
 * @LastEditors: liuxia
 * @LastEditTime: 2023-02-21 16:02:25
 */
import { queryOtherMajorQd } from '@/api/costQuotaLibrary/costLibrary';
/**
 * 计算综合单价
 * @param record
 * @returns
 */
export function getTotalPrice(record) {
  if (!record?.deList?.length) {
    return '请先关联定额';
  }
  let total = 0;
  record.deList.forEach((item) => {
    const quantity = nanToNumber(item.quantity) || 1;
    total += Number((getPrice(item) * quantity).toFixed(2));
  });
  record.price = total.toFixed(2);
  return total.toFixed(2);
}

/**
 * 计算定额综合单价
 * @returns
 */
export function getPrice({ rfee, cfee, jfee, managerFee }) {
  const total = Number(
    (
      nanToNumber(rfee) +
      nanToNumber(cfee) +
      nanToNumber(jfee) +
      nanToNumber(managerFee)
    ).toFixed(2)
  );
  return total;
}

function nanToNumber(val) {
  return isNaN(val) ? 0 : Number(val);
}

/**
 * 组价成本前置处理
 * @param vue
 * @param record
 * @param index
 * @returns
 */
export function costBeforeHandler(vue, record, index) {
  if (record.projectAttr === '') {
    return vue.$message.error('项目特征不能为空');
  }
  const { bdCode, bdName, projectAttr, unit } = record;
  queryOtherMajorQd({
    bdCode,
    bdName,
    projectAttr,
    unit,
    majorNbr: vue.currentMajorInfo?.sequenceNbr,
    agencyCode: vue.userInfo?.agencyCode,
  }).then(({ status, result }) => {
    if (status !== 200 || result) {
      return vue.$message.error('该清单与其他二级下清单存在相同');
    }
    record.customIndex = index;
    // this.currentActionRecord = record;
    // this.costVisible = true;
    Object.assign(vue, {
      currentActionRecord: record,
      costVisible: true,
    });
  });
}

/**
 * 检测用户身份
 */
export function checkUserIdentity(vue) {
  const { userType, enterprisePassed } = vue.$store.state.user.userInfo;
  if (userType !== 'Enterprise') {
    vue.$message.error(`完成企业认证后即可应用企业成本组价管理`);
    return false;
  }
  if (userType !== 'Enterprise' && enterprisePassed) {
    vue.$message.error('请切换为企业身份后再进行企业成本组价管理使用');
    return false;
  }
  return true;
}
