































































import {
  enterpriseQuota,
  qyStlBillDeSave,
} from '@/api/costQuotaLibrary/costLibrary';
import Vue from 'vue';
import combinationTable from '../components/combinationTable.vue';
import { QyStlBillDeType } from '@/interface/costQuotaLibrary';
import { mapGetters } from 'vuex';
export default Vue.extend({
  name: 'costLibraryBatchImport',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
    },
  },
  watch: {
    url: function (val, oldVal) {
      if (val) this.enterpriseQuotaCheck();
    },
  },
  components: { combinationTable },
  computed: {
    ...mapGetters(['userInfo']),
  },
  data() {
    return {
      loading: true,
      saveLoading: false,
      detailedListBind: {
        actionList: [],
        tableData: [],
        customAction: true,
        showPagination: false,
      },
      screenValue: 'all',
      saveVisible: false,
      modalVisible: false,
      allData: [] as QyStlBillDeType[],
      failData: [] as QyStlBillDeType[],
      emptyData: [] as QyStlBillDeType[],
    };
  },
  mounted() {
    this.enterpriseQuotaCheck();
  },
  methods: {
    filtersData() {
      const map = {
        all: this.allData,
        fail: this.failData,
        empty: this.emptyData,
      };
      this.$set(this.detailedListBind, 'tableData', map[this.screenValue]);
    },
    enterpriseQuotaCheck() {
      this.screenValue = 'all';
      if (!this.url) return;
      enterpriseQuota(this.url)
        .then(({ status, result }) => {
          const { code, data, msg } = result;
          if (code !== 0) {
            this.handleCancel();
            return this.$message.error(msg);
          }
          let failData: QyStlBillDeType[] = [];
          let emptyData: QyStlBillDeType[] = [];
          data.map((item, index) => {
            item.sequenceNbr = '1000000' + index;
            item.customIndex = index;
            if (item.deList && item.deList.length) {
              item.deList.map((deItem, deIndex) => {
                deItem.sequenceNbr = `${item.sequenceNbr}${deIndex}`;
                deItem = this.setFailClass(deItem);
                return deItem;
              });
            }
            if (item.isError) failData.push(item);
            if (item.isNull) emptyData.push(item);
            return item;
          });
          this.allData = data;
          this.failData = failData;
          this.emptyData = emptyData;
          this.modalVisible = true;
          this.$nextTick(() => {
            this.$set(this.detailedListBind, 'tableData', data);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setFailClass(deItem) {
      const editFields = ['quantity', 'rfee', 'cfee', 'jfee', 'managerFee'];
      deItem.customCellClass = {};
      editFields.forEach((item) => {
        if (deItem[item] === -1 && deItem.errorMessage[item]) {
          deItem.customCellClass[item] = 'fail-term';
          deItem[item] = deItem.errorMessage[item];
        }
        if (deItem[item] === null) {
          deItem.customCellClass[item] = 'fail-term';
          deItem[item] = '';
        }
      });
      return deItem;
    },
    editHandler({ record, key, index }) {
      (this.detailedListBind.tableData as QyStlBillDeType[]).splice(
        index,
        1,
        record
      );
    },
    quotaEditHandler({
      record,
      key,
      index,
      parentIndex,
      parentRecord,
      eventName,
    }) {
      const deList =
        (this.detailedListBind.tableData[parentIndex] as QyStlBillDeType)
          .deList || [];
      deList.splice(index, 1, record);
      if (eventName === 'focus') return;
      this.handlerParentFailNullVal(deList, parentIndex);
      // this.handlerScreenData();
    },
    /**
     * 根据修改值，处理父级错误、空值状态
     */
    handlerParentFailNullVal(deList, parentIndex) {
      let failNum = 0;
      let nullNum = 0;
      for (let i = 0; i < deList.length; i++) {
        const { isError, isNull } = deList[i];
        if (isError) failNum++;
        if (isNull) nullNum++;
      }
      this.$set(
        this.detailedListBind.tableData[parentIndex],
        'isError',
        failNum ? 1 : 0
      );
      this.$set(
        this.detailedListBind.tableData[parentIndex],
        'isNull',
        nullNum ? 1 : 0
      );
    },
    /**
     * 根据更改的文本框值处理各数组值
     */
    handlerScreenData() {
      // const screenValue = this.screenValue;
      // if (screenValue === 'fail') {
      //   if (!this.failData[parentIndex].isError) {
      //     this.failData.splice(parentIndex, 1);
      //   }
      // }
      // if (screenValue === 'empty') {
      //   if (!this.emptyData[parentIndex].isNull) {
      //     this.emptyData.splice(parentIndex, 1);
      //   }
      // }
      // if (screenValue === 'all') {
      let failData: QyStlBillDeType[] = [];
      let emptyData: QyStlBillDeType[] = [];
      this.allData.map((item, index) => {
        if (item.isError) failData.push(item);
        if (item.isNull) emptyData.push(item);
        return item;
      });
      this.emptyData = emptyData;
      this.failData = failData;
      // }
      this.filtersData();
    },
    /**
     * 选中得数据
     */
    rowSelectChange({ selectedRowKeys, selectedRows }) {
      console.log('index.vue|34====>', selectedRowKeys, selectedRows);
    },
    handleCancel() {
      this.$emit('update:visible', false);
      this.modalVisible = false;
    },
    saveCancel() {
      this.saveVisible = false;
      this.screenValue = 'fail';
      this.handlerScreenData();
      const keys: string[] = this.failData.map((item) => item.sequenceNbr);
      (this.$refs.combinationTable as any).setExpandedRowKeys(keys);
    },
    save() {
      this.saveLoading = true;
      this.handlerScreenData();
      if (this.failData.length) {
        this.saveLoading = false;
        return (this.saveVisible = true);
      }
      qyStlBillDeSave({
        xiliOpenId: this.userInfo?.openId,
        qyStlBillDeList: this.allData,
        agencyCode: this.userInfo?.agencyCode,
      })
        .then(({ status, result }) => {
          if (status === 200 && result) {
            this.handleCancel();
            this.$emit('refresh');
            return this.$message.success('保存成功');
          }
          this.$message.error('保存失败');
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
  },
});
